import React, {useContext} from 'react';
import Avatar from '@mui/material/Avatar';
import {useAppDispatch, useAuthUser} from '../../../@template/utility/AppHooks';
import AppContext from '../../../@template/utility/AppContext';
import clsx from 'clsx';
import {makeStyles} from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import {orange} from '@mui/material/colors';
import {Fonts, ThemeMode} from '../../constants/AppEnums';
import AppContextPropsType from '../../../types/AppContextPropsType';
import {AuthUser} from '../../../types/models/AuthUser';
import {authLogin, authLogOut} from '../../../redux/auth/Actions';
import {useMsal} from '@azure/msal-react';
import {Theme} from '@mui/material';
import AccountSwitchDialog from './AccountSwitchDialog';
import {selectSettingsAccount, selectSettingsInstructorAccounts} from '../../../redux/settings/Selectors';
import {useSelector} from 'react-redux';
import {hasItems} from '../../../@template/helpers/arrays';
import {authConfig, coreApiConfig} from '../../../config';
import authenticatedApi, {setAuthToken} from '../../../@template/services/auth/authApi/authenticatedApi';
import {SilentRequest} from '@azure/msal-browser';
import {useNavigate} from 'react-router-dom';
import {generatePath} from 'react-router';
import {accountPaths} from '../../../modules/accounts/paths';

const useStyles = makeStyles((theme: Theme) => {
  return {
    crUserInfo: {
      backgroundColor: 'rgba(0,0,0,.08)',
      paddingTop: 9,
      paddingBottom: 9,
      minHeight: 56,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        paddingTop: 10,
        paddingBottom: 10,
        minHeight: 70,
      },
    },
    profilePic: {
      fontSize: 24,
      backgroundColor: orange[500],
    },
    userInfo: {
      width: 'calc(100% - 75px)',
    },
    userName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 16,
      fontWeight: Fonts.MEDIUM,
      color: (props: {themeMode: ThemeMode}) =>
        props.themeMode === ThemeMode.LIGHT ? '#313541' : 'white',
    },
    designation: {
      marginTop: -2,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: theme.palette.text.secondary,
    },
    pointer: {
      cursor: 'pointer',
    },
  };
});

const UserInfo: React.FC = () => {
  const navigate = useNavigate();
  const {themeMode} = useContext<AppContextPropsType>(AppContext);
  const dispatch = useAppDispatch();
  const user: AuthUser | undefined = useAuthUser();

  const { instance } = useMsal();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [accountSwitchOpen, setAccountSwitchOpen] = React.useState(false);
  const [accountSwitchLoading, setAccountSwitchLoading] = React.useState(false);

  const settingsAccount = useSelector(selectSettingsAccount);
  const instructorAccounts = useSelector(selectSettingsInstructorAccounts);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (event: any) => {
    dispatch(authLogOut());
    instance.logoutRedirect();
  }

  const getUserAvatar = () => {
    if (user && user.displayName) {
      return user.displayName.charAt(0).toUpperCase();
    }
    if (user && user.email) {
      return user.email.charAt(0).toUpperCase();
    }
  };

  const canSwitch = () => {
    if(hasItems(instructorAccounts)){
      for(const acc of instructorAccounts){
        if(acc.accountId !== settingsAccount.accountId){
          return true;
        }
      }
    }
    return false;
  }

  const canEditAccount = () => {
    if(hasItems(instructorAccounts)) {
      for (const acc of instructorAccounts) {
        if(acc.accountId === settingsAccount.id){
          return acc.isAccountAdmin;
        }
      }
    }
    return false;
  }

  const handleSwitch = (accountId: string) => {
    setAccountSwitchLoading(true);

    const url = `${coreApiConfig.Paths.auth}/instructor/account/${accountId}`;
    authenticatedApi.request({
      baseURL: coreApiConfig.Url,
      url: url,
      method: 'GET',
    }).then((response) => {
      const authUser: AuthUser = new AuthUser(response.data);

      // handleLogout(undefined);
      const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
      delay(30000).then(() => {
        const authAccountId = localStorage.getItem('AUTH_ACCT_ID');
        if(authAccountId){
          const currentAccount = instance.getAccountByHomeId(authAccountId)
          console.log('currentAccount', currentAccount)
          const loginRequest: SilentRequest = {
            scopes: [`api://${authConfig.clientId}/admin.user`, 'User.Read', 'email'],
            account: currentAccount || undefined
          };

          console.log('loginRequest', loginRequest)
          instance.acquireTokenSilent(loginRequest).then(tokenResponse => {
            setAuthToken(tokenResponse.accessToken, tokenResponse.expiresOn, authAccountId);

            // Try this with a proper page refresh as the cache buster does. That should fire auth login
            dispatch(authLogin());
            setAccountSwitchOpen(false);
            setAccountSwitchLoading(false);
          });
        }
      });
    }).catch(error => {
      setAccountSwitchLoading(false);
    });
  }

  const handleCloseSwitching = () => {
    setAccountSwitchOpen(false)
  }

  const classes = useStyles({themeMode});

  return (
      <React.Fragment>
        <AccountSwitchDialog
          isOpen={accountSwitchOpen}
          isSubmitting={accountSwitchLoading}
          onClose={handleCloseSwitching}
          onSubmit={handleSwitch}
          settingsAccount={settingsAccount}
          instructorAccounts={instructorAccounts}
        />
        <Box
            px={{xs: 4, xl: 7}}
            className={clsx(classes.crUserInfo, 'cr-user-info')}>
          <Box display='flex' alignItems='center'>
            {user && user.photoUrl ? (
                <Avatar className={classes.profilePic} src={user.photoUrl} />
            ) : (
                <Avatar className={classes.profilePic}>{getUserAvatar()}</Avatar>
            )}
            <Box ml={4} className={clsx(classes.userInfo, 'user-info')}>
              <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'>
                <Box mb={0} className={clsx(classes.userName)}>
                  {user && user.firstName} { user && user.lastName}
                </Box>
                <Box
                    ml={3}
                    className={classes.pointer}
                    color={themeMode === 'light' ? '#313541' : 'white'}>
                  <ExpandMoreIcon onClick={handleClick} />
                  <Menu
                      id='simple-menu'
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}>
                    {canSwitch() && <MenuItem
                        onClick={() => {
                          setAccountSwitchOpen(true)
                          handleClose();
                        }}
                    >Switch Account</MenuItem>}
                    {canEditAccount() && <MenuItem
                        onClick={() => navigate(generatePath(accountPaths.AccountProfile, { idParam: settingsAccount.id }))}
                    >My account</MenuItem>}
                    <MenuItem onClick={handleLogout}>
                      Logout
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
              <Box className={classes.designation}>Instructor</Box>
            </Box>
          </Box>
        </Box>
      </React.Fragment>
  );
};

export default UserInfo;
