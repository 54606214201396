import React from 'react';
import {AppRouteData, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {sessionPaths} from './paths';


const sessionSectionBreadcrumbs = [new BreadcrumbItemProps('Sessions', sessionPaths.SessionSearch)];

const DetailPage = React.lazy(() => import('./SessionProfile'));
const SearchPage = React.lazy(() => import('./SessionSearch'));

const sessionRouteConfig = [
    { path: sessionPaths.SessionSearch, element: <SearchPage />, handle: AppRouteData.create('Search', undefined, sessionSectionBreadcrumbs) } ,
    { path: sessionPaths.SessionProfile, element: <DetailPage />, handle: AppRouteData.create('Details', undefined, sessionSectionBreadcrumbs) } ,
];

export default sessionRouteConfig;