

export const defaultForInstructorUserSearchRequest = () => {
    return InstructorUserSearchRequest.fromJS({
        searchText: '',
        includeDisabled: false,
    });
}

export class InstructorUserSearchRequest implements IInstructorUserSearchRequest {
    searchText?: string | undefined;
    includeDisabled?: boolean;
    dateAddedFrom?: Date | undefined;
    dateAddedTo?: Date | undefined;

    constructor(data?: IInstructorUserSearchRequest) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.searchText = _data['searchText'];
            this.includeDisabled = _data['includeDisabled'];
            this.dateAddedFrom = _data['dateAddedFrom'] ? new Date(_data['dateAddedFrom'].toString()) : <any>undefined;
            this.dateAddedTo = _data['dateAddedTo'] ? new Date(_data['dateAddedTo'].toString()) : <any>undefined;
        }
    }

    static fromJS(data: any): InstructorUserSearchRequest {
        data = typeof data === 'object' ? data : {};
        const result = new InstructorUserSearchRequest();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['searchText'] = this.searchText;
        data['includeDisabled'] = this.includeDisabled;
        data['dateAddedFrom'] = this.dateAddedFrom ? this.dateAddedFrom.toISOString() : <any>undefined;
        data['dateAddedTo'] = this.dateAddedTo ? this.dateAddedTo.toISOString() : <any>undefined;
        return data;
    }
}

export interface IInstructorUserSearchRequest {
    searchText?: string | undefined;
    includeDisabled?: boolean;
    dateAddedFrom?: Date | undefined;
    dateAddedTo?: Date | undefined;
}