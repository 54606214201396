import {AccountParticipant} from '../models/AccountParticipant';
import {SessionParticipant} from '../models/SessionParticipant';
import {SessionParticipantSuggestedAccountParticipant} from './SessionParticipantSuggestedAccountParticipant';

export class SessionParticipantView implements ISessionParticipantView {
    participant?: SessionParticipant | undefined;
    accountParticipant?: AccountParticipant | undefined;
    suggestedAccountParticipant?: SessionParticipantSuggestedAccountParticipant | undefined;

    constructor(data?: ISessionParticipantView) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.participant = _data['participant'] ? SessionParticipant.fromJS(_data['participant']) : <any>undefined;
            this.accountParticipant = _data['accountParticipant'] ? AccountParticipant.fromJS(_data['accountParticipant']) : <any>undefined;
            this.suggestedAccountParticipant = _data['suggestedAccountParticipant'] ? SessionParticipantSuggestedAccountParticipant.fromJS(_data['suggestedAccountParticipant']) : <any>undefined;
        }
    }

    static fromJS(data: any): SessionParticipantView {
        data = typeof data === 'object' ? data : {};
        const result = new SessionParticipantView();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['participant'] = this.participant ? this.participant.toJSON() : <any>undefined;
        data['accountParticipant'] = this.accountParticipant ? this.accountParticipant.toJSON() : <any>undefined;
        data['suggestedAccountParticipant'] = this.suggestedAccountParticipant ? this.suggestedAccountParticipant.toJSON() : <any>undefined;
        return data;
    }
}

export interface ISessionParticipantView {
    accountParticipant?: AccountParticipant | undefined;
    participant?: SessionParticipant | undefined;
    suggestedAccountParticipant?: SessionParticipantSuggestedAccountParticipant | undefined;
}