import React, {ReactNode, useEffect, useState} from 'react';
import {useNavigate, useLocation, useMatches} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useAppDispatch, useAuthToken} from './AppHooks';
import {Loader} from '../index';
import {checkPermission} from './Utils';
import {authPaths} from '../../modules/auth/paths';
import {dashboardPaths} from '../../modules/dashboard/paths';
import {selectAuth, selectCurrentUser} from '../../redux/auth/Selectors';
import {
  selectCountriesLoading,
  selectSettingsLoading,
  setSettingsInitialized
} from '../../redux/settings/Selectors';
import {AppRouteData} from '../../types/AppRoutesPropType';
import {useMsal} from '@azure/msal-react';

interface AuthRoutesProps {
  children: ReactNode;
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({children}) => {
  const {pathname, search} = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const matches = useMatches();

  const [initialPath, setInitialPath] = useState(localStorage.getItem('INITIAL_URL'));
  const currentRoute = AppRouteData.fromJS(matches[0].handle);

  const [loading] = useAuthToken(currentRoute?.auth);
  const { inProgress } = useMsal();

  const { loggingIn, refreshing, error } = useSelector(selectAuth);
  const isInitialised  = useSelector(setSettingsInitialized);
  const sessionLoading = useSelector(selectSettingsLoading);
  const countriesLoading = useSelector(selectCountriesLoading);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    function setInitPath() {
      if (
          initialPath === '/' &&
          [
            authPaths.SignIn,
            authPaths.Error404,
            dashboardPaths.LandingPage
          ].indexOf(pathname) === -1
      ) {
        setInitialPath(pathname);
      }
    }
    setInitPath();
  }, [initialPath, pathname]);

  useEffect(() => {
    const isPermitted = checkPermission(currentRoute.auth, currentUser ? currentUser.permissions : null);
    console.log('loading, logging in, refreshing, permitted, user', loading, loggingIn, refreshing, isPermitted, currentUser);

    if (!loading && !loggingIn && !refreshing && !sessionLoading) {
      if (!currentUser || error) {
        navigate(authPaths.SignIn); // allowed route
      } else if (currentUser && !isPermitted) {
        navigate('/error-pages/error-404'); // Not found
      }
      else if (currentUser && isPermitted) {
        if (
            pathname === '/' ||
            pathname === authPaths.SignIn
        ) {
          if (
              initialPath &&
              dashboardPaths.LandingPage !== initialPath &&
              initialPath !== '/'
          ) {
            navigate(initialPath);
          } else {
            navigate(dashboardPaths.LandingPage);
          }
        }
      }
    }
  }, [currentUser, loading, loggingIn, refreshing, initialPath, pathname, history, error, isInitialised, sessionLoading]);

  return loading || loggingIn || refreshing || sessionLoading ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;
