import {all, call, put, takeEvery} from '@redux-saga/core/effects';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {
    localitiesError,
    localitiesLoaded,
    SETTINGS,
    settingsError,
    settingsLoaded,
    tagsUpdateFile,
    tagsUpdateFileSystem
} from './Actions';
import {filesApiConfig} from '../../config';
import {AxiosResponse} from 'axios';
import {parseErrorInfo} from '../../@template/helpers/errors';
import {Country} from '../../types/models/Country';
import {InstructorUserSettings} from '../../types/views/InstructorUserSettings';

export function* LoadUserSession() {
    console.log('SAGA - sessionSaga - LoadUserSession');
    try {
        const res: AxiosResponse = yield authApi.get('/settings/instructor');
        const sessionData = InstructorUserSettings.fromJS(res.data);
        yield put(settingsLoaded(sessionData));

        const fileRes: AxiosResponse = yield authApi.request({
            baseURL: filesApiConfig.Url,
            method: 'PUT',
            url: '/session'
        });
        const fileData = fileRes.data;
        yield put(tagsUpdateFile(fileData?.tagListModel?.fileTags?? []));
        yield put(tagsUpdateFileSystem(fileData?.tagListModel?.systemTags?? []));
    } catch (err) {
        yield put(settingsError(parseErrorInfo(err)));
    }
}

export function* LoadLocalities() {
    console.log('SAGA - sessionSaga - LoadLocalities');
    try {
        const res: AxiosResponse = yield authApi.get('/settings/localities');
        const countries: Country[] = [];
        res.data?.forEach((x: any) => {
            countries.push(new Country(x));
        });
        yield put(localitiesLoaded(countries));
    } catch (err) {
        yield put(localitiesError(parseErrorInfo(err)));
    }
}

export function* watchLoadUserSession(){
    yield takeEvery(SETTINGS.LOAD, LoadUserSession)
}

export function* watchLoadLocalities(){
    yield takeEvery(SETTINGS.LOCALITIES_LOAD, LoadLocalities)
}

export default function* settingsSaga() {
    yield all ([
        call(watchLoadUserSession),
        call(watchLoadLocalities),
    ])
}