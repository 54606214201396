import {ICommonSaveActionType, ICommonSavePayload } from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {AccountEdit} from '../../types/requests/AccountEdit';
import {Account} from '../../types/models/Account';
import {IError} from '../../@template/helpers/errors';


export const ACCOUNTS: ICommonSaveActionType = {
    SAVE: 'account/save',
    SAVED: 'account/saveSuccess',
    SAVE_ERROR: 'account/saveError',
    LOAD: 'account/load',
    REFRESH: 'account/refresh',
    LOAD_ERROR: 'account/loadError',
    LOADED: 'account/loadSuccess',
}


export const accountSave = createAction<ICommonSavePayload<AccountEdit>>(ACCOUNTS.SAVE);
export const accountSaveSuccess = createAction<Account>(ACCOUNTS.SAVED);
export const accountSaveError = createAction<IError>(ACCOUNTS.SAVE_ERROR);
export const accountLoad = createAction<string>(ACCOUNTS.LOAD);
export const accountRefresh = createAction<string>(ACCOUNTS.REFRESH);
export const accountLoadSuccess = createAction<Account>(ACCOUNTS.LOADED);
export const accountLoadError = createAction<string>(ACCOUNTS.LOAD_ERROR);

