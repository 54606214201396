import {Account} from '../../types/models/Account';
import {IError} from '../../@template/helpers/errors';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {ACCOUNTS_PARTICIPANT, ACCOUNTS_SEARCH} from './Actions';
import {SearchRequest} from '../../types/common/SearchRequest';
import {defaultForAccountParticipantSearchRequest, AccountParticipantSearchRequest} from '../../types/requests/AccountParticipantSearchRequest';
import {DefaultSortByOptions} from '../../types/common/SortByOptions/DefaultSortByOptions';
import {SortOrderOptions} from '../../types/common/SortOrderOptions';
import {PagedEntityListOfAccountParticipantSearchItem} from '../../types/views/PagedEntityListOfAccountParticipantSearchItem';

export interface IAccountParticipantState {
    accountParticipant: Account | undefined;
    accountParticipantLoading: boolean | false;
    accountParticipantLoadError: string | undefined;
    accountParticipantSaving: boolean | false;
    accountParticipantSaveError: IError | undefined;
    accountParticipantSearchParams: AccountParticipantSearchRequest;
    accountParticipantSearchSortOption: DefaultSortByOptions;
    accountParticipantSearchSortOrder: SortOrderOptions;
    accountParticipantsResult: PagedEntityListOfAccountParticipantSearchItem | undefined;
    accountParticipantsSearching: boolean;
    accountParticipantSearchError: string | undefined;
}

const initialState: IAccountParticipantState = {
    accountParticipant: undefined,
    accountParticipantLoading: false,
    accountParticipantLoadError: undefined,
    accountParticipantSaving: false,
    accountParticipantSaveError: undefined,
    accountParticipantSearchParams: defaultForAccountParticipantSearchRequest(),
    accountParticipantSearchSortOption: DefaultSortByOptions.Relevance,
    accountParticipantSearchSortOrder: SortOrderOptions.Desc,
    accountParticipantsResult: undefined,
    accountParticipantsSearching: false,
    accountParticipantSearchError: undefined,
}

const accountReducer = createReducer(initialState, {
    [ACCOUNTS_PARTICIPANT.LOAD]: (state) => {
        state.accountParticipant = undefined;
        state.accountParticipantLoading = true;
        state.accountParticipantLoadError = undefined;
        state.accountParticipantSaveError = undefined;
    },
    [ACCOUNTS_PARTICIPANT.REFRESH]: (state) => {
        state.accountParticipantLoading = false;
        state.accountParticipantLoadError = undefined;
        state.accountParticipantSaveError = undefined;
    },
    [ACCOUNTS_PARTICIPANT.LOADED]: (state, action: PayloadAction<Account>) => {
        state.accountParticipantLoading = false;
        state.accountParticipantLoadError = undefined;
        state.accountParticipant = action.payload;
    },
    [ACCOUNTS_PARTICIPANT.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
        state.accountParticipantLoading = false;
        state.accountParticipantLoadError = action.payload;
        state.accountParticipant = undefined;
    },
    [ACCOUNTS_PARTICIPANT.SAVE]: (state) => {
        state.accountParticipantSaving = true;
        state.accountParticipantSaveError = undefined;
    },
    [ACCOUNTS_PARTICIPANT.SAVED]: (state, action: PayloadAction<Account>) => {
        state.accountParticipantSaving = false;
        state.accountParticipantSaveError = undefined;
        state.accountParticipant = action.payload;
    },
    [ACCOUNTS_PARTICIPANT.SAVE_ERROR]: (state, action: PayloadAction<IError>) => {
        state.accountParticipantSaving = false;
        state.accountParticipantSaveError = action.payload;
    },
    [ACCOUNTS_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<AccountParticipantSearchRequest>>) => {
        state.accountParticipantSearchParams = action.payload.searchParams;
        state.accountParticipantSearchSortOption = action.payload.sortBy??(DefaultSortByOptions.Relevance);
        state.accountParticipantSearchSortOrder = action.payload.sortDirection??(SortOrderOptions.Desc);
        state.accountParticipantsSearching = true;
        state.accountParticipantSearchError = undefined;
    },
    [ACCOUNTS_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
        state.accountParticipantsSearching = false;
        state.accountParticipantSearchError = action.payload;
    },
    [ACCOUNTS_SEARCH.LOADED]: (state, action: PayloadAction<PagedEntityListOfAccountParticipantSearchItem>) => {
        state.accountParticipantsSearching = false;
        state.accountParticipantSearchError = undefined;
        state.accountParticipantsResult = action.payload;
    },
});

export default accountReducer;