import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@mui/material';
import DialogCloseButton from '../../../@template/core/DialogCloseButton';
import React from 'react';
import {IActionDialogProps} from '../../../types/common/ActionDialogProps';
import {Account} from '../../../types/models/Account';
import {InstructorAccountAccess} from '../../../types/views/InstructorAccountAccess';
import Button from '@mui/material/Button';
import GridContainer from '../../../@template/core/GridContainer';
import {Loader} from '../../../@template';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface IAccountSwitchDialog extends IActionDialogProps<string>{
  settingsAccount: Account | undefined;
  instructorAccounts: InstructorAccountAccess[] | undefined;
}

const AccountSwitchDialog = ({ isOpen, onClose, onSubmit, settingsAccount, instructorAccounts, isSubmitting }: IAccountSwitchDialog) => {
  const handleSwitch = (accountId: string) => {
    onSubmit(accountId);
  }

  const handleClose = (e: any, reason?: string | undefined) => {
    if (reason !== 'backdropClick' && onClose) onClose();
  }

  return <Dialog
    fullWidth={true}
    maxWidth="md"
    open={isOpen}
    onClose={handleClose}>
    <DialogTitle>
      Currently logged in as an instructor for: <strong>{settingsAccount?.companyName}</strong>.
      Select account to switch to below.
      <DialogCloseButton onClick={handleClose} />
    </DialogTitle>
    <DialogContent>
      <GridContainer
          spacing={2}
      >
      {isSubmitting &&
        <Grid item xs={12}>
          <Typography variant='body1'>Swapping Account...</Typography>
          <Loader />
        </Grid>
      }
      {!isSubmitting && instructorAccounts?.filter(ia => ia.accountId !== settingsAccount?.id).map(ia =>
          <React.Fragment key={ia.accountId}>
            <Grid item xs={5} sm={3} md={3}>
              <Button
                  variant="outlined"
                  sx={{width: '100%'}}
                  onClick={() => handleSwitch(ia.accountId?? '')}
              >
                Select account
              </Button>
            </Grid>
            <Grid item xs={7} sm={9} md={9}>
              <Box sx={{display: 'flex', height: '100%', alignItems: 'center'}}>
                {ia.companyName}
              </Box>
            </Grid>
          </React.Fragment>
      )}
      </GridContainer>
    </DialogContent>
    <DialogActions>
      {!isSubmitting && <Button onClick={handleClose} variant="contained" color="secondary" size="medium">
        Cancel
      </Button>}
    </DialogActions>
  </Dialog>;
}
export default AccountSwitchDialog;
