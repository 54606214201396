import React, {useEffect, useState} from 'react';
import {useAppDispatch} from '../../../@template/utility/AppHooks';
import {useSelector} from 'react-redux';
import {generatePath} from 'react-router';
import Link from '@mui/material/Link';
import {personCacheAdd} from '../../../redux/personCache/Actions';
import Box from '@mui/material/Box';
import {NavLink} from '../../../@template';
import {AppState} from '../../../redux/store';
import {PersonListItem} from '../../../types/views/PersonListItem';
import {instructorPaths } from '../../instructors/paths';
import { isEmptyOrWhitespace } from '@template/helpers/strings';

interface IPersonLinkProps {
  personId?: string | undefined,
  className?: string | undefined,
  linkProfile?: boolean | undefined,
  forceContactLink?: boolean | undefined,
  overwriteLinkPath?: string | undefined,
  onClick?(): void | undefined,
}

const getCacheItem = (id: string | undefined) => (state: AppState) => {
  if (!id) return undefined;
  return state.peopleCache.personCache.find((x: PersonListItem) => x.id === id);
}

const PersonLink = ({ personId, linkProfile, className, onClick, forceContactLink, overwriteLinkPath }: IPersonLinkProps) => {
  const dispatch = useAppDispatch();
  const [ avatarPersonId ] = useState(personId);

  const listItem: PersonListItem | undefined = useSelector(getCacheItem(personId));

  useEffect(() => {
    if (!listItem && avatarPersonId) {
      dispatch(personCacheAdd([avatarPersonId]));
    }
  }, [ avatarPersonId ]);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  const getUrl = () => {
    let url = '#';
    if(overwriteLinkPath){
      url = overwriteLinkPath;
    } else if (listItem) {
      // TODO: include admin users
      if (listItem.isInstructor && !forceContactLink) {
        url = generatePath(instructorPaths.InstructorProfile, { idParam: listItem.id });
      }
      if (listItem.isContact) {
        url = generatePath(instructorPaths.InstructorProfile, { idParam: listItem.id });
      }
    }
    return url;
  }

  const displayName = isEmptyOrWhitespace(listItem?.firstName) && isEmptyOrWhitespace(listItem?.lastName) ? 'Not entered' : `${listItem?.firstName} ${listItem?.lastName}`;

  if (onClick && listItem) {
    return <Box component="span" className={className}>
      <Link component="button" onClick={handleClick}>{displayName}</Link>
    </Box>;
  }
  if (listItem && linkProfile && getUrl() !== '#') {
    return (
        <Box component="span" className={className}>
          <NavLink to={getUrl()} >{displayName}</NavLink>
        </Box>
    );
  } else if (listItem) {
    return <Box component="span" className={className}>
      {displayName}
    </Box>;
  }
  return <Box component="span" className={className}>
    Loading person
  </Box>
};

export default PersonLink;