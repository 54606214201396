import {dashboardPaths} from './dashboard/paths';
import {instructorPaths} from './instructors/paths';
import {sessionPaths} from './sessions/paths';
import {participantPaths} from './accountParticipants/paths';

export interface NavItemProps {
    id: string;
    label: string;
    title: string;
    icon?: string;
    exact?: boolean;
    url?: string;
    type?: string;
    count?: number;
    color?: string;
    auth?: string[];
    children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
    {
        id: 'home',
        title: 'Dashboard',
        label: 'Dashboard',
        type: 'item',
        icon: 'dashboard',
        url: dashboardPaths.LandingPage,
    },
    {
        id: 'account-participants',
        title: 'Account Participants',
        label: 'Account Participants',
        type: 'collapse',
        icon: 'badge',
        children: [
            {
                id: 'accounts-participants-manage',
                title: 'Manage Participants',
                label: 'Manage Participants',
                type: 'item',
                url: participantPaths.ParticipantSearch,
            },
            {
                id: 'accounts-participants-add',
                title: 'Add Participant',
                label: 'Add Participant',
                type: 'item',
                url: participantPaths.ParticipantCreate,
            }
        ],
    },
    {
        id: 'sessions',
        title: 'Sessions',
        label: 'Sessions',
        type: 'collapse',
        icon: 'view_in_ar',
        children: [
            {
                id: 'sessions-manage',
                title: 'Search sessions',
                label: 'Search sessions',
                type: 'item',
                url: sessionPaths.SessionSearch,
            }
        ],
    },
    {
        id: 'instructor-users',
        title: 'Instructor Accounts',
        label: 'Instructor Accounts',
        type: 'collapse',
        icon: 'people',
        children: [
            {
                id: 'instructor-user-manage',
                title: 'Manage accounts',
                label: 'Manage accounts',
                type: 'item',
                url: instructorPaths.InstructorSearch,
            }
        ],
    }
];
export default routesConfig;
