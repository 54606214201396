import {ICommonLoadActionType, ICommonSearchActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {SessionView} from '../../types/views/SessionView';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {SessionSearchRequest} from '../../types/requests/SessionSearchRequest';
import {PagedEntityListOfSessionSearchItem} from '../../types/views/PagedEntityListOfSessionSearchItem';


export const SESSIONS: ICommonLoadActionType = {
    LOAD: 'session/load',
    REFRESH: 'session/refresh',
    ERROR: 'session/loadError',
    LOADED: 'session/loadSuccess',
}

export const SESSION_SEARCH: ICommonSearchActionType = {
    CHANGE_VIEW: 'sessionSearch/changeView',
    ERROR: 'sessionSearch/error',
    LOAD: 'sessionSearch/load',
    LOADED: 'sessionSearch/loaded',
    REFRESH: 'sessionSearch/refresh'
}

export const sessionLoad = createAction<string>(SESSIONS.LOAD);
export const sessionRefresh = createAction<string>(SESSIONS.REFRESH);
export const sessionLoadSuccess = createAction<SessionView>(SESSIONS.LOADED);
export const sessionLoadError = createAction<string>(SESSIONS.ERROR);
export const sessionSearch = createAction<ISearchRequest<SessionSearchRequest>>(SESSION_SEARCH.LOAD);
export const sessionSearchSuccess = createAction<PagedEntityListOfSessionSearchItem>(SESSION_SEARCH.LOADED);
export const sessionSearchError = createAction<string>(SESSION_SEARCH.ERROR);