import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {Account} from '../../types/models/Account';
import {ACCOUNTS} from './Actions';
import {IError} from '../../@template/helpers/errors';

export interface IAccountState {
    account: Account | undefined;
    accountLoading: boolean | false;
    accountLoadError: string | undefined;
    accountSaving: boolean | false;
    accountSaveError: IError | undefined;
}

const initialState: IAccountState = {
    account: undefined,
    accountLoading: false,
    accountLoadError: undefined,
    accountSaving: false,
    accountSaveError: undefined,
}

const accountReducer = createReducer(initialState, {
    [ACCOUNTS.LOAD]: (state) => {
        state.account = undefined;
        state.accountLoading = true;
        state.accountLoadError = undefined;
        state.accountSaveError = undefined;
    },
    [ACCOUNTS.REFRESH]: (state) => {
        state.accountLoading = false;
        state.accountLoadError = undefined;
        state.accountSaveError = undefined;
    },
    [ACCOUNTS.LOADED]: (state, action: PayloadAction<Account>) => {
        state.accountLoading = false;
        state.accountLoadError = undefined;
        state.account = action.payload;
    },
    [ACCOUNTS.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
        state.accountLoading = false;
        state.accountLoadError = action.payload;
        state.account = undefined;
    },
    [ACCOUNTS.SAVE]: (state) => {
        state.accountSaving = true;
        state.accountSaveError = undefined;
    },
    [ACCOUNTS.SAVED]: (state, action: PayloadAction<Account>) => {
        state.accountSaving = false;
        state.accountSaveError = undefined;
        state.account = action.payload;
    },
    [ACCOUNTS.SAVE_ERROR]: (state, action: PayloadAction<IError>) => {
        state.accountSaving = false;
        state.accountSaveError = action.payload;
    },
});

export default accountReducer;