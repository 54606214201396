import React from 'react';
import List from '@mui/material/List';

import routesConfig, {NavItemProps} from '../../../../modules/routesConfig';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import VerticalNavGroup from './VerticalNavGroup';
import {useSelector} from 'react-redux';
import {selectSettingsAccount, selectSettingsInstructorAccounts} from '../../../../redux/settings/Selectors';
import {hasItems} from '../../../helpers/arrays';

interface NavigationProps {}

const Navigation: React.FC<NavigationProps> = () => {
  const settingsAccount = useSelector(selectSettingsAccount);
  const instructorAccounts = useSelector(selectSettingsInstructorAccounts);

  const canEditInstructors = () => {
    if(hasItems(instructorAccounts)) {
      for (const acc of instructorAccounts) {
        if(acc.accountId === settingsAccount.id){
          return acc.isAccountAdmin;
        }
      }
    }
    return false;
  }

  return (
    <List>
      {routesConfig.map((item: NavItemProps) => {
        if(!canEditInstructors() && item.id === 'instructor-users') return
        return (
            <React.Fragment key={item.id}>
              {item.type === 'group' && <VerticalNavGroup item={item} level={0} />}

              {item.type === 'collapse' && (
                  <VerticalCollapse item={item} level={0} />
              )}

              {item.type === 'item' && <VerticalItem item={item} level={0} />}
            </React.Fragment>
        )
      })}
    </List>
  );
};

export default Navigation;
