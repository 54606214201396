import React from 'react';
import Box from '@mui/material/Box';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import {BadgeProps, ListItemButton, styled, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Fonts} from '../../../shared/constants/AppEnums';
import clsx from 'clsx';
import {ActivityThread} from '../../../types/models/ActivityThread';
import {AuthUser} from '../../../types/models/AuthUser';
import AppNavLink from '../AppNavLink';
import {generatePath} from 'react-router';
import {inboxPaths} from '../../../modules/messaging/paths';
import Badge from '@mui/material/Badge';
import {hasItems} from '../../helpers/arrays';
import PersonAvatar from '../../../modules/people/PersonAvatar';
import PersonLink from '../../../modules/people/PersonProfileLink';
import AccountAvatar from '../../../modules/accounts/AccountAvatar';

const useStyles = makeStyles(() => ({
  textBase: {
    fontSize: 14,
  },
  avatar: {
    width: 48,
    height: 48,
  },
  minWidth0: {
    minWidth: 0,
  },
  listItemRoot: {
    padding: '8px 20px',
  },
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 2,
    top: 10,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const useThreadItemStyles = makeStyles((theme: Theme) => ({
  groupItem: {
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.text.primary,
    '&.MuiListItem-root': {
      paddingTop: '2px',
      paddingBottom: '5px',
      paddingLeft: '18px',
      paddingRight: '12px',
    },
    '& .MuiListItemText-root': {
      flex: 1,
      display: 'block'
    },
  },
  textTruncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

interface IThreadMetadata {
  recipientIds: string[] | undefined;
  internalIds: string[] | undefined;
  recipientAccountId: string | undefined;
  companyThread: boolean;
}

interface NotificationItemProps {
  threadItem: ActivityThread;
  currentUser: AuthUser | undefined;
}

const NotificationItem: React.FC<NotificationItemProps> = ({threadItem, currentUser}) => {
  const classes = useStyles();
  const threadItemClasses = useThreadItemStyles();

  const metadataFromThread = (): IThreadMetadata => {
    let recipientIds: string[] = [];
    let internalIds: string[] = [];
    let recipientAccountId: string | undefined = undefined;
    let companyThread = false;
    // If this is a conversation with a student or contact
    if (hasItems(threadItem.recipientPeopleMetadata)) {
      recipientIds = threadItem.recipientPeopleMetadata?.filter(x => !x.isInternal).map(x => x.personId?? '') ?? [];
      internalIds = threadItem.recipientPeopleMetadata?.filter(x => x.isInternal).map(x => x.personId?? '') ?? [];
    }
    if (threadItem.recipientCompaniesMetadata && hasItems(threadItem.recipientCompaniesMetadata)){
      companyThread = true;
      recipientAccountId = threadItem.recipientCompaniesMetadata[0].accountId;
    }
    if (!recipientIds || recipientIds.length === 0) {
      // We have no external recipients, just show the other advisers.
      if (threadItem.recipientPeopleMetadata && hasItems(threadItem.recipientPeopleMetadata)) {
        recipientIds = threadItem.recipientPeopleMetadata.filter(x => x.personId !== currentUser?.personId).map(x => x.personId?? '');
      }
    }
    return {
      recipientIds, recipientAccountId, internalIds, companyThread
    };
  }

  const avatarForRecipientList = () => {
    const threadMetadata = metadataFromThread();
    if (!threadMetadata.recipientIds && !threadMetadata.recipientAccountId) {
      return <StyledBadge badgeContent={threadItem.unreadCount} color="secondary"><Avatar sx={{ fontSize: 36 }} className={classes.avatar} /></StyledBadge>;
    }
    if (threadMetadata.recipientIds && hasItems(threadMetadata.recipientIds)) return <StyledBadge badgeContent={threadItem.unreadCount} color="secondary"><PersonAvatar size={36} personId={threadMetadata.recipientIds[0]} /></StyledBadge>;
    return <StyledBadge badgeContent={threadItem.unreadCount} color="secondary"><AccountAvatar size={36} accountId={threadMetadata.recipientAccountId??''} /></StyledBadge>;
  }
  
  const threadTitle = () => {
    const threadMetadata = metadataFromThread();
    if (threadMetadata.recipientIds && hasItems(threadMetadata.recipientIds)) {
      return <Box component="h5" className={threadItemClasses.textTruncate}>
        {threadMetadata.recipientIds?.map((personId: string, idx: number) => <React.Fragment key={`${personId}-${idx}-rec-ids`}>{idx > 0 ? ', ' : ''}<PersonLink
          personId={personId} /></React.Fragment>)}
      </Box>;
    }
    return <Box component="h5" className={threadItemClasses.textTruncate}>Context not found</Box>;
  }
  
  return (
    <ListItemButton
      key={`hdr-not-${threadItem.id}`}
      component={AppNavLink}
      to={generatePath(inboxPaths.InboxThreadView, { idParam: threadItem.id })}
      className={clsx(classes.listItemRoot, 'item-hover')}>
      <Box mr={4}>
        <ListItemAvatar className={classes.minWidth0}>
          {avatarForRecipientList()}
        </ListItemAvatar>
      </Box>
      <Box className={classes.textBase} color='text.secondary'>
        <Box
          mr={2}
          component='span'
          display='inline-block'
          color='text.primary'
          fontWeight={Fonts.MEDIUM}>
          {threadTitle()}
        </Box>
        <Box className={threadItemClasses.textTruncate}>{threadItem.lastMessage??'Other activity'}</Box>
      </Box>
    </ListItemButton>
  );
};

export default NotificationItem;
