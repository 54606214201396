import React from 'react';
import {AppRouteData, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {accountPaths} from './paths';
import {dashboardPaths} from '../dashboard/paths';


const accountSectionBreadcrumbs = [new BreadcrumbItemProps('Accounts', dashboardPaths.LandingPage)];

const EditPage = React.lazy(() => import('./AccountEdit'));
const DetailPage = React.lazy(() => import('./AccountProfile'));

const accountRouteConfig = [
    { path: accountPaths.AccountProfile, element: <DetailPage />, handle: AppRouteData.create('Details', undefined, accountSectionBreadcrumbs) } ,
    { path: accountPaths.AccountCreate, element: <EditPage />, handle: AppRouteData.create('Create', undefined, accountSectionBreadcrumbs) } ,
    { path: accountPaths.AccountEdit, element: <EditPage />, handle: AppRouteData.create('Edit', undefined, accountSectionBreadcrumbs) } ,
];

export default accountRouteConfig;