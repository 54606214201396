import {AccountParticipantSearchItem} from './AccountParticipantSearchItem';
import {SortOrderOptions} from '../common/SortOrderOptions';

export class PagedEntityListOfAccountParticipantSearchItem implements IPagedEntityListOfAccountParticipantSearchItem {
    pageSize?: number;
    sortByColumnString?: string | undefined;
    sortOrderOptionsDirection?: SortOrderOptions;
    sortLetter?: string | undefined;
    currentPage?: number;
    totalRows?: number;
    sortByString?: string | undefined;
    totalPages?: number;
    skipRows?: number;
    takeRows?: number;
    list?: AccountParticipantSearchItem[] | undefined;

    constructor(data?: IPagedEntityListOfAccountParticipantSearchItem) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.pageSize = _data['pageSize'];
            this.sortByColumnString = _data['sortByColumnString'];
            this.sortOrderOptionsDirection = _data['sortOrderOptionsDirection'];
            this.sortLetter = _data['sortLetter'];
            this.currentPage = _data['currentPage'];
            this.totalRows = _data['totalRows'];
            this.sortByString = _data['sortByString'];
            this.totalPages = _data['totalPages'];
            this.skipRows = _data['skipRows'];
            this.takeRows = _data['takeRows'];
            if (Array.isArray(_data['list'])) {
                this.list = [] as any;
                for (const item of _data['list'])
                    this.list!.push(AccountParticipantSearchItem.fromJS(item));
            }
        }
    }

    static fromJS(data: any): PagedEntityListOfAccountParticipantSearchItem {
        data = typeof data === 'object' ? data : {};
        const result = new PagedEntityListOfAccountParticipantSearchItem();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['pageSize'] = this.pageSize;
        data['sortByColumnString'] = this.sortByColumnString;
        data['sortOrderOptionsDirection'] = this.sortOrderOptionsDirection;
        data['sortLetter'] = this.sortLetter;
        data['currentPage'] = this.currentPage;
        data['totalRows'] = this.totalRows;
        data['sortByString'] = this.sortByString;
        data['totalPages'] = this.totalPages;
        data['skipRows'] = this.skipRows;
        data['takeRows'] = this.takeRows;
        if (Array.isArray(this.list)) {
            data['list'] = [];
            for (const item of this.list)
                data['list'].push(item.toJSON());
        }
        return data;
    }
}

export interface IPagedEntityListOfAccountParticipantSearchItem {
    pageSize?: number;
    sortByColumnString?: string | undefined;
    sortOrderOptionsDirection?: SortOrderOptions;
    sortLetter?: string | undefined;
    currentPage?: number;
    totalRows?: number;
    sortByString?: string | undefined;
    totalPages?: number;
    skipRows?: number;
    takeRows?: number;
    list?: AccountParticipantSearchItem[] | undefined;
}