import {ICommonSaveActionType, ICommonSavePayload, ICommonSearchActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {AccountEdit} from '../../types/requests/AccountEdit';
import {Account} from '../../types/models/Account';
import {IError} from '../../@template/helpers/errors';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {AccountParticipantSearchRequest} from '../../types/requests/AccountParticipantSearchRequest';
import {PagedEntityListOfAccountParticipantSearchItem} from '../../types/views/PagedEntityListOfAccountParticipantSearchItem';

export const ACCOUNTS_PARTICIPANT: ICommonSaveActionType = {
    SAVE: 'accountParticipant/save',
    SAVED: 'accountParticipant/saveSuccess',
    SAVE_ERROR: 'accountParticipant/saveError',
    LOAD: 'accountParticipant/load',
    REFRESH: 'accountParticipant/refresh',
    LOAD_ERROR: 'accountParticipant/loadError',
    LOADED: 'accountParticipant/loadSuccess',
}

export const ACCOUNTS_SEARCH: ICommonSearchActionType = {
    CHANGE_VIEW: 'accountParticipantSearch/changeView',
    ERROR: 'accountParticipantSearch/error',
    LOAD: 'accountParticipantSearch/load',
    LOADED: 'accountParticipantSearch/loaded',
    REFRESH: 'accountParticipantSearch/refresh'
}

export const accountParticipantSave = createAction<ICommonSavePayload<AccountEdit>>(ACCOUNTS_PARTICIPANT.SAVE);
export const accountParticipantSaveSuccess = createAction<Account>(ACCOUNTS_PARTICIPANT.SAVED);
export const accountParticipantSaveError = createAction<IError>(ACCOUNTS_PARTICIPANT.SAVE_ERROR);
export const accountParticipantLoad = createAction<string>(ACCOUNTS_PARTICIPANT.LOAD);
export const accountParticipantRefresh = createAction<string>(ACCOUNTS_PARTICIPANT.REFRESH);
export const accountParticipantLoadSuccess = createAction<Account>(ACCOUNTS_PARTICIPANT.LOADED);
export const accountParticipantLoadError = createAction<string>(ACCOUNTS_PARTICIPANT.LOAD_ERROR);

export const accountParticipantSearch = createAction<ISearchRequest<AccountParticipantSearchRequest>>(ACCOUNTS_SEARCH.LOAD);
export const accountParticipantSearchSuccess = createAction<PagedEntityListOfAccountParticipantSearchItem>(ACCOUNTS_SEARCH.LOADED);
export const accountParticipantSearchError = createAction<string>(ACCOUNTS_SEARCH.ERROR);