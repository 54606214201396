import {ICommonSavePayload} from '../Common.actions';
import {AccountEdit} from '../../types/requests/AccountEdit';
import authenticatedApi from '../../@template/services/auth/authApi/authenticatedApi';
import {coreApiConfig} from '../../config';
import {AxiosResponse} from 'axios';
import {Account} from '../../types/models/Account';
import {all, call, put, takeEvery} from '@redux-saga/core/effects';
import {parseErrorInfo, parseErrorToErrorClass} from '../../@template/helpers/errors';
import {
    accountSaveSuccess,
    accountSaveError,
    accountLoadSuccess,
    ACCOUNTS,
    accountLoadError,
} from './Actions';
import {PayloadAction} from '@reduxjs/toolkit';

export function* SaveAccount({ id, submission }: ICommonSavePayload<AccountEdit>) {
    console.log('SAGA - accountSaga - SaveAccount');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: id ? 'PUT' : 'POST',
            url: id ? `${coreApiConfig.Paths.account}/${id}` : coreApiConfig.Paths.account,
            data: submission
        });
        const account: Account = Account.fromJS(res.data);
        yield put(accountSaveSuccess(account));
    } catch (err) {
        yield put(accountSaveError(parseErrorToErrorClass(err)));
    }
}


export function* LoadAccount(id: string) {
    console.log('SAGA - accountSaga - LoadAccount');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: 'GET',
            url: `${coreApiConfig.Paths.account}/${id}`,
        });
        const account: Account = Account.fromJS(res.data);
        yield put(accountLoadSuccess(account));
    } catch (err) {
        yield put(accountLoadError(parseErrorInfo(err)));
    }
}


export function* watchSaveAccount(){
    yield takeEvery(ACCOUNTS.SAVE, ({ payload } : PayloadAction<ICommonSavePayload<AccountEdit>>) => SaveAccount(payload));
}

export function* watchLoadAccount(){
    yield takeEvery(ACCOUNTS.LOAD, ({ payload } : PayloadAction<string>) => LoadAccount(payload));
}


export default function* accountSaga() {
    yield all ([
        call(watchSaveAccount),
        call(watchLoadAccount),
    ])
}