import {RouteTabParameter} from '../../types/common/RouteTabParameter';

export interface IParticipantPaths {
    ParticipantEdit: string,
    ParticipantCreate: string,
    ParticipantProfile: string,
    ParticipantSearch: string,
}

export const participantPaths: IParticipantPaths = {
    ParticipantEdit: '/participants/edit/:idParam',
    ParticipantCreate: '/participants/create',
    ParticipantProfile: '/participants/view/:idParam',
    ParticipantSearch: '/participants/search',
};

export interface IParticipantProfileTabs {
    activity: RouteTabParameter;
    details: RouteTabParameter;
}

export const participantProfileTabs: IParticipantProfileTabs = {
    activity: new RouteTabParameter(0, 'Activity', 'activity'),
    details: new RouteTabParameter(3, 'Details', 'details')
}

export const profileTabs = [
    participantProfileTabs.activity,
    participantProfileTabs.details
];

export const getParticipantProfileTabs = (query: string | undefined | null) => profileTabs.find(x => x.query === query);

