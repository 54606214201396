export class InstructorInstructorUserDetailView implements IInstructorInstructorUserDetailView {
    accountId?: string;
    isDisabled?: boolean;
    dateDisabled?: Date | undefined;
    disabledByUserId?: string | undefined;
    disabledReason?: string | undefined;
    isAccountAdmin?: boolean;
    isSessionAdmin?: boolean;
    isCurrentAccount?: boolean;
    jobTitle?: string | undefined;
    emailSignature?: string | undefined;
    jobDepartment?: string | undefined;
    userReference?: string | undefined;

    constructor(data?: IInstructorInstructorUserDetailView) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.accountId = _data['accountId'];
            this.isDisabled = _data['isDisabled'];
            this.dateDisabled = _data['dateDisabled'] ? new Date(_data['dateDisabled'].toString()) : <any>undefined;
            this.disabledByUserId = _data['disabledByUserId'];
            this.disabledReason = _data['disabledReason'];
            this.isAccountAdmin = _data['isAccountAdmin'];
            this.isSessionAdmin = _data['isSessionAdmin'];
            this.isCurrentAccount = _data['isCurrentAccount'];
            this.jobTitle = _data['jobTitle'];
            this.emailSignature = _data['emailSignature'];
            this.jobDepartment = _data['jobDepartment'];
            this.userReference = _data['userReference'];
        }
    }

    static fromJS(data: any): InstructorInstructorUserDetailView {
        data = typeof data === 'object' ? data : {};
        const result = new InstructorInstructorUserDetailView();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['accountId'] = this.accountId;
        data['isDisabled'] = this.isDisabled;
        data['dateDisabled'] = this.dateDisabled ? this.dateDisabled.toISOString() : <any>undefined;
        data['disabledByUserId'] = this.disabledByUserId;
        data['disabledReason'] = this.disabledReason;
        data['isAccountAdmin'] = this.isAccountAdmin;
        data['isSessionAdmin'] = this.isSessionAdmin;
        data['isCurrentAccount'] = this.isCurrentAccount;
        data['jobTitle'] = this.jobTitle;
        data['emailSignature'] = this.emailSignature;
        data['jobDepartment'] = this.jobDepartment;
        data['userReference'] = this.userReference;
        return data;
    }
}

export interface IInstructorInstructorUserDetailView {
    accountId?: string;
    isDisabled?: boolean;
    dateDisabled?: Date | undefined;
    disabledByUserId?: string | undefined;
    disabledReason?: string | undefined;
    isAccountAdmin?: boolean;
    isSessionAdmin?: boolean;
    isCurrentAccount?: boolean;
    jobTitle?: string | undefined;
    emailSignature?: string | undefined;
    jobDepartment?: string | undefined;
    userReference?: string | undefined;
}