import React, {useState} from 'react';
import {useAppDispatch} from '../../../@template/utility/AppHooks';
import BusinessIcon from '@mui/icons-material/Business';
import Avatar from '@mui/material/Avatar';
import {useSelector} from 'react-redux';
import {selectSettingsAccount} from '../../../redux/settings/Selectors';

interface IAccountIconProps {
  accountId: string,
  size?: number | undefined,
  overrideColor?: string | undefined,
  onClick?(): void | undefined,
}


const AccountAvatar = ({ accountId, onClick, overrideColor, size = 20 }: IAccountIconProps) => {
  const dispatch = useAppDispatch();
  const [ avatarAccountId ] = useState(accountId);

  const account = useSelector(selectSettingsAccount);

  // const listItem = useSelector(getCacheItem(avatarAccountId));
  // const imageItem = useSelector(getImageCacheItem(listItem?.logoImageId));
  //
  // useEffect(() => {
  //   if (!listItem && avatarAccountId) {
  //     dispatch(accountCacheAdd([avatarAccountId]));
  //   }
  // }, [ avatarAccountId ]);
  //
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };
  //
  // if(listItem && listItem.logoImageFileId){
  //   return (
  //     <Tooltip placement="bottom" title={listItem.accountName ?? '?'}>
  //       <>
  //         <Box onClick={(e) => handleClick(e)}>
  //           <Avatar sx={{ width: size, height: size}} variant="square">
  //             <SecureImage
  //               src={getImageUrlFromId(listItem.logoImageFileId, ImageProfile.Thumb)}
  //               width={size}
  //               height={size}
  //             />
  //           </Avatar>
  //         </Box>
  //       </>
  //     </Tooltip>
  //   );
  // }
  // if (listItem) {
  //   const initials = (listItem.accountName ?? '?').charAt(0).toUpperCase();
  //   if (listItem.logoImageId && imageItem) {
  //     const imageUrl = `${filesApiConfig.Paths.image}${imageConfig.imageSize.imagesLarge}/${imageItem.filename}`;
  //     return (
  //       <Tooltip placement="bottom" title={listItem.accountName ?? '?'}>
  //         <>
  //           <Box onClick={(e) => handleClick(e)}>
  //             <Avatar src={imageUrl} sx={{ width: size, height: size}} variant="square">{initials}</Avatar>
  //           </Box>
  //         </>
  //       </Tooltip>
  //     );
  //   }
  //
  //   const backgroundColor = overrideColor ? overrideColor : selectAccountColor(initials);
  //   const fontColor = '#efefef';
  //
  //   return (
  //     <Tooltip placement="bottom" title={listItem.accountName ?? '?'}>
  //       <>
  //         {listItem?.picture && <Box onClick={(e) => handleClick(e)}><Avatar src={listItem.picture} sx={{ width: size, height: size}} variant="rounded"/></Box>}
  //         {!listItem?.picture && <Box onClick={(e) => handleClick(e)}><Avatar sx={{ backgroundColor: backgroundColor, color: fontColor, fontSize: `${size * 0.5}px`, width: size, height: size }} variant="rounded">
  //           {initials}
  //         </Avatar></Box>}
  //       </>
  //     </Tooltip>
  //   );
  // }
  return <Avatar onClick={(e) => handleClick(e)} sx={{ backgroundColor: '#dfdfdf', width: size, height: size }}><BusinessIcon /></Avatar>;
};

export default AccountAvatar;