import {createBrowserRouter} from 'react-router-dom';
import dashboardRoutesConfig from './dashboard';
import {AppRouteData, BreadcrumbItemProps} from '../types/AppRoutesPropType';
import React from 'react';
import ErrorPage from './errorPages/Error500';
import ExternalLayout from '../@template/core/AppLayout/ExternalLayout';
import AppLayout from '../@template/core/AppLayout';
import authRouteConfig from './auth';
import errorPagesConfig from './errorPages';
import accountRouteConfig from './accounts';
import messagingRouteConfig from './messaging';
import sessionRouteConfig from './sessions';
import instructorRouteConfig from './instructors';
import participantPaths from './accountParticipants';

const internalRouteConfigs = [
    ...dashboardRoutesConfig,
    ...accountRouteConfig,
    ...sessionRouteConfig,
    ...messagingRouteConfig,
    ...instructorRouteConfig,
    ...participantPaths
];

const defaultSectionBreadcrumbs: BreadcrumbItemProps[] = [];

const defaultRouteConfig = [
    {
        path: '/signin', element: <ExternalLayout />,
        handle: AppRouteData.create('Welcome', undefined, defaultSectionBreadcrumbs),
        errorElement: <ErrorPage />,
        children: [
            ...authRouteConfig
        ]
    } ,
    { path: '/error',
        element: <ExternalLayout />,
        handle: AppRouteData.create('Error', undefined, defaultSectionBreadcrumbs),
        errorElement: <ErrorPage />,
        children: [
            ...errorPagesConfig
        ]
    } ,
    { path: '/',
        element: <AppLayout />,
        handle: AppRouteData.create('Internal', undefined, defaultSectionBreadcrumbs),
        errorElement: <ErrorPage />,
        children: [
            ...internalRouteConfigs
        ]
    } ,
];

const defaultRoutes = createBrowserRouter([...defaultRouteConfig]);


export default defaultRoutes;
