export class SessionParticipantSuggestedAccountParticipant implements ISessionParticipantSuggestedAccountParticipant {
    accountParticipantId?: string;
    fullName?: string | undefined;
    email?: string | undefined;

    constructor(data?: ISessionParticipantSuggestedAccountParticipant) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.accountParticipantId = _data['accountParticipantId'];
            this.fullName = _data['fullName'];
            this.email = _data['email'];
        }
    }

    static fromJS(data: any): SessionParticipantSuggestedAccountParticipant {
        data = typeof data === 'object' ? data : {};
        const result = new SessionParticipantSuggestedAccountParticipant();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['accountParticipantId'] = this.accountParticipantId;
        data['fullName'] = this.fullName;
        data['email'] = this.email;
        return data;
    }
}

export interface ISessionParticipantSuggestedAccountParticipant {
    accountParticipantId?: string;
    fullName?: string | undefined;
    email?: string | undefined;
}