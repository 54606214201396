export interface IAccountPaths {
    AccountEdit: string,
    AccountCreate: string,
    AccountProfile: string,
}

export const accountPaths: IAccountPaths = {
    AccountEdit: '/accounts/edit/:idParam',
    AccountCreate: '/accounts/create',
    AccountProfile: '/accounts/view/:idParam',
};
