import React from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import {Theme} from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Popover from '@mui/material/Popover';
import {makeStyles} from '@mui/styles';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Scrollbar from '../Scrollbar';
import clsx from 'clsx';
import NotificationItem from './NotificationItem';
import {Fonts} from '../../../shared/constants/AppEnums';
import {useSelector} from 'react-redux';
import {selectNotificationsSummary} from '../../../redux/messaging/Selectors';
import {useAuthUser} from '../../utility/AppHooks';
import AppNavLink from '../AppNavLink';
import {generatePath} from 'react-router';
import {inboxPaths} from '../../../modules/messaging/paths';

const useStyles = makeStyles((theme: Theme) => ({
  crPopover: {
    '& .MuiPopover-paper': {
      width: 260,
      [theme.breakpoints.up('sm')]: {
        width: 300,
      },
      [theme.breakpoints.up('xl')]: {
        width: 380,
      },
    },
    '& .scroll-submenu': {
      maxHeight: 200,
      [theme.breakpoints.up('xl')]: {
        maxHeight: 380,
      },
    },
  },
  btnPopover: {
    borderRadius: 0,
    width: '100%',
    textTransform: 'capitalize',
  },
  notiBtn: {
    justifyContent: 'flex-start',
    width: '100%',
    height: 56,
    fontSize: 16,
    borderRadius: 0,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    color: theme.palette.grey[800],
    '&:hover, &:focus': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.up('sm')]: {
      height: 70,
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      width: 'auto',
      borderLeft: 'solid 1px',
      borderLeftColor: theme.palette.grey[200],
      color: theme.palette.grey[400],
      '&:hover, &:focus': {
        color: theme.palette.text.primary,
      },
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: '2.5rem',
      paddingRight: '2.5rem',
    },
  },
  notiIcon: {
    fontSize: 22,
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('xl')]: {
      fontSize: 30,
    },
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  badge: {
    marginRight: 8,
  },
}));

interface NotificationsProps {}

const Notifications: React.FC<NotificationsProps> = () => {
  const notificationSummary = useSelector(selectNotificationsSummary);
  const currentUser = useAuthUser();
  const [
    anchorNotification,
    setAnchorNotification,
  ] = React.useState<HTMLButtonElement | null>(null);

  const onClickNotificationButton = (
      event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorNotification(event.currentTarget);
  };

  const classes = useStyles();

  const totalIncomplete = () => {
    if (!notificationSummary || !notificationSummary.summaries) return 0;
    const totalIncomplete = notificationSummary.summaries.reduce((a, b) => {
      return a + (b.incompleteEvents ? b.incompleteEvents : 0);
    }, 0);
    return totalIncomplete < 100 ? totalIncomplete : 99;
  }

  return (
      <>
        <IconButton
            className={clsx(classes.notiBtn, 'notiBtn')}
            aria-label='show new notifications'
            color='inherit'
            onClick={onClickNotificationButton}>
          <Badge
              className={classes.badge}
              badgeContent={totalIncomplete()}
              color='secondary'>
            <NotificationsActiveIcon
                className={clsx(classes.notiIcon, 'notiIcon')}
            />
          </Badge>
          <Box
              sx={{ display: { md: 'none', xs: 'block' } }}
              ml={4}
              fontSize={16}
              fontWeight={Fonts.REGULAR}
              component='span'>
            Notifications
          </Box>
        </IconButton>

        <Popover
            anchorEl={anchorNotification}
            id='language-switcher'
            className={classes.crPopover}
            keepMounted
            open={Boolean(anchorNotification)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            onClose={() => setAnchorNotification(null)}>
          <Box>
            <Box px={5} py={3}>
              <h4>
                Notifications ({totalIncomplete()})
              </h4>
            </Box>
            <Scrollbar className='scroll-submenu'>
              <List
                  className={classes.list}
                  onClick={() => {
                    setAnchorNotification(null);
                  }}>
                {notificationSummary?.incompleteThreadList?.map((item, index) => (
                    <NotificationItem key={`tb-not-${index}-${item.id}`} threadItem={item} currentUser={currentUser} />
                ))}
                {!notificationSummary?.incompleteThreadList?.length && <Box m={4} sx={{ textAlign: 'center' }}>No unread notifications</Box>}
              </List>
            </Scrollbar>
            <Box mt={2}>
              <Button
                  component={AppNavLink}
                  className={classes.btnPopover}
                  to={generatePath(inboxPaths.InboxView)}
                  variant='contained'
                  color='primary'>
                View inbox
              </Button>
            </Box>
          </Box>
        </Popover>
      </>
  );
};

export default Notifications;
