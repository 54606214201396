import {AxiosResponse} from 'axios';
import authenticatedApi from '../../@template/services/auth/authApi/authenticatedApi';
import {coreApiConfig} from '../../config';
import {
    SESSION_SEARCH,
    sessionLoadError,
    sessionLoadSuccess,
    SESSIONS,
    sessionSearchError,
    sessionSearchSuccess
} from './Actions';
import {SessionView} from '../../types/views/SessionView';
import {SessionSearchRequest} from '../../types/requests/SessionSearchRequest';
import {SearchRequest} from '../../types/common/SearchRequest';
import {all, call, put, takeEvery} from '@redux-saga/core/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import {parseErrorInfo} from '../../@template/helpers/errors';
import {PagedEntityListOfSessionSearchItem} from '../../types/views/PagedEntityListOfSessionSearchItem';


export function* LoadSession(id: string) {
    console.log('SAGA - sessionSaga - LoadSession');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: 'GET',
            url: `${coreApiConfig.Paths.session}/${id}`,
        });
        const session: SessionView = SessionView.fromJS(res.data);
        yield put(sessionLoadSuccess(session));
    } catch (err) {
        yield put(sessionLoadError(parseErrorInfo(err)));
    }
}

export function* SearchSessions({ page, pageSize, searchParams, sortBy, sortDirection }: SearchRequest<SessionSearchRequest>) {
    console.log('SAGA - sessionSaga - SearchSessions');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: 'PUT',
            url: `${coreApiConfig.Paths.session}/search?page=${page}&page_size=${pageSize}&sort=${sortBy}&order=${sortDirection}`,
            data: searchParams
        });
        const results: PagedEntityListOfSessionSearchItem = PagedEntityListOfSessionSearchItem.fromJS(res.data);
        yield put(sessionSearchSuccess(results));
    } catch (err) {
        yield put(sessionSearchError(parseErrorInfo(err)));
    }
}

export function* watchLoadSession(){
    yield takeEvery(SESSIONS.LOAD, ({ payload } : PayloadAction<string>) => LoadSession(payload));
}

export function* watchRefreshSession(){
    yield takeEvery(SESSIONS.REFRESH, ({ payload } : PayloadAction<string>) => LoadSession(payload));
}

export function* watchSearchSessions(){
    yield takeEvery(SESSION_SEARCH.LOAD, ({ payload } : PayloadAction<SearchRequest<SessionSearchRequest>>) => SearchSessions(payload));
}

export default function* sessionSaga() {
    yield all ([
        call(watchRefreshSession),
        call(watchLoadSession),
        call(watchSearchSessions),
    ])
}