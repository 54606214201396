export class InstructorAccountAccess implements IInstructorAccountAccess {
    accountId?: string;
    companyName?: string | undefined;
    isAccountAdmin?: boolean;
    isSessionAdmin?: boolean;
    isCurrentAccount?: boolean;

    constructor(data?: IInstructorAccountAccess) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.accountId = _data['accountId'];
            this.companyName = _data['companyName'];
            this.isAccountAdmin = _data['isAccountAdmin'];
            this.isSessionAdmin = _data['isSessionAdmin'];
            this.isCurrentAccount = _data['isCurrentAccount'];
        }
    }

    static fromJS(data: any): InstructorAccountAccess {
        data = typeof data === 'object' ? data : {};
        const result = new InstructorAccountAccess();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['accountId'] = this.accountId;
        data['companyName'] = this.companyName;
        data['isAccountAdmin'] = this.isAccountAdmin;
        data['isSessionAdmin'] = this.isSessionAdmin;
        data['isCurrentAccount'] = this.isCurrentAccount;
        return data;
    }
}

export interface IInstructorAccountAccess {
    accountId?: string;
    companyName?: string | undefined;
    isAccountAdmin?: boolean;
    isSessionAdmin?: boolean;
    isCurrentAccount?: boolean;
}