import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {toggleNavCollapsed} from '../../../../redux/ui/Actions';
import Box from '@mui/material/Box';
import useStyles from './AppHeader.style';
import Notifications from '../../Notifications';
import AppLogo from '../../../../shared/components/AppLogo';
import clsx from 'clsx';
import {useAppDispatch} from '../../../utility/AppHooks';

const AppHeader: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const timeoutInterval = 10000;
  const [init, setInit] = useState(false);

  // const notificationSummaryLoading = useSelector(selectNotificationsSummaryLoading);
  // const notificationsSummaryError = useSelector(selectNotificationsSummaryError);
  // const notificationsSummaryRefreshing = useSelector(selectNotificationsSummaryRefreshing);

  const [
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl,
  ] = useState<null | HTMLElement>(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMobileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  // useEffect(() => {
  //   if (!init) {
  //     dispatch(notificationsLoad());
  //     setInit(true);
  //   }
  // }, [init]);

  // const refreshNotifications = () => {
  //   // Get the latest from date
  //   if (!notificationSummaryLoading && !notificationsSummaryRefreshing && !notificationsSummaryError && init) {
  //     dispatch(notificationsLoadRefresh());
  //   }
  // };

  // useRecursiveTimeout(() =>
  //   new Promise<void>(r => {
  //     refreshNotifications();
  //     r();
  //   }), timeoutInterval);

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
      <Menu
          anchorEl={mobileMoreAnchorEl}
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          id={mobileMenuId}
          keepMounted
          transformOrigin={{vertical: 'top', horizontal: 'right'}}
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}>
        <MenuItem className={classes.menuItemRoot}>
          <Notifications />
        </MenuItem>
      </Menu>
  );

  return (
      <>
        <AppBar color='inherit' className={clsx(classes.appBar, 'app-bar')}>
          <Toolbar className={classes.appToolbar}>
            <IconButton
                edge='start'
                className={classes.menuButton}
                color='inherit'
                aria-label='open drawer'
                onClick={() => dispatch(toggleNavCollapsed())}>
              <MenuIcon className={classes.menuIcon} />
            </IconButton>

            <AppLogo />
            <Box className={classes.grow} />
            <Box className={classes.sectionDesktop}>
              <Notifications />
            </Box>
            <Box className={classes.sectionMobile}>
              <IconButton
                  aria-label='show more'
                  aria-controls={mobileMenuId}
                  aria-haspopup='true'
                  onClick={handleMobileMenuOpen}
                  color='inherit'>
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </>
  );
};
export default AppHeader;
