import axios, {AxiosError, Method} from 'axios';
import {coreApiConfig} from '../../../../config';

export const defaultHeaderValues = {
  ContentType: 'application/json',
  XOperatorContext: 'Instructor',
  XOperatorSource: 'InstructorPortal'
}
// Context only downgrades user for admin acting as instructor
// does not escalate permissions
const authRequest = axios.create({
  baseURL: coreApiConfig.Url,
  headers: {
    'Content-Type': defaultHeaderValues.ContentType,
    'X-Operator-Context': defaultHeaderValues.XOperatorContext,
    'X-Operator-Source': defaultHeaderValues.XOperatorSource
  },
});
authRequest.interceptors.response.use(
  res => res,
  (error: AxiosError) => {
    console.error('Request Failed:', error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
      if (error.response && error.response.status === 401) {
        // store.dispatch({type: LOGOUT});
      }
      return Promise.reject(error.response);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message);
      return Promise.reject(error.message);
    }
  },
);

export const authCall = (baseUrl: string, method: Method, path: string, body: object | null) => {
  return axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
      'X-Operator-Context': 'Instructor',
      'X-Operator-Source': 'InstructorPortal'
    },
    method: method,
    url: path,
    data: body
  });
}


export const setAuthToken = (token: string | null, expiresOn: Date | null, accountId: string | null) => {
  console.log('authenticatedApi - SETTING TOKEN', token);
  if (token && accountId && expiresOn) {
    authRequest.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    localStorage.setItem('AUTH_TOKEN', token);
    localStorage.setItem('AUTH_EXPIRY', expiresOn.toISOString());
    localStorage.setItem('AUTH_ACCT_ID', accountId);
  } else {
    delete authRequest.defaults.headers.common['Authorization'];
    localStorage.removeItem('AUTH_TOKEN');
    localStorage.removeItem('AUTH_EXPIRY');
    localStorage.removeItem('AUTH_SID');
  }
};

export const getAuthToken = () => {
  return localStorage.getItem('AUTH_TOKEN')
}

export default authRequest;
