import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {Country, ICountry} from '../../types/models/Country';
import {SETTINGS} from './Actions';
import {InstructorUserSettings} from '../../types/views/InstructorUserSettings';
import {Account} from '../../types/models/Account';
import {InstructorAccountAccess} from '../../types/views/InstructorAccountAccess';
import {AgeRange} from '../../types/views/AgeRange';


export interface ISessionState {
    account: Account | undefined;
    instructorAccounts: InstructorAccountAccess[] | undefined;
    settingsLoading: boolean | false;
    settingsError: string | undefined;
    settingsInitialized: boolean;
    countries: Country[] | undefined;
    fileTags: string[] | undefined;
    fileSystemTags: string[] | undefined;
    countriesLoading: boolean;
    countriesError: string | undefined;
    ageRanges: AgeRange[] | undefined;
}

const initialState: ISessionState = {
    account: undefined,
    instructorAccounts: [],
    settingsLoading: false,
    settingsInitialized: false,
    settingsError: undefined,
    fileTags: [],
    fileSystemTags: [],
    countries: [],
    countriesLoading: false,
    countriesError: undefined,
    ageRanges: []
}

const settingsReducer = createReducer(initialState, {
    [SETTINGS.LOAD]: (state) => {
        state.settingsLoading = true;
        state.settingsError = undefined;
    },
    [SETTINGS.LOADED]: (state, action: PayloadAction<InstructorUserSettings>) => {
        const {account, instructorAccounts, ageRanges} = action.payload;
        state.settingsInitialized = true;
        state.settingsLoading = false;
        state.account = account;
        state.instructorAccounts = instructorAccounts;
        state.ageRanges = ageRanges;
    },
    [SETTINGS.ERROR]: (state, action: PayloadAction<string>) => {
        state.settingsInitialized = true;
        state.settingsLoading = false;
        state.settingsError = action.payload;
    },
    [SETTINGS.LOCALITIES_LOAD]: (state) => {
        state.countriesLoading = true;
        state.countriesError = undefined;
    },
    [SETTINGS.LOCALITIES_LOADED]: (state, action: PayloadAction<ICountry[]>) => {
        state.countriesLoading = false;
        state.countries = action.payload;
    },
    [SETTINGS.LOCALITIES_ERROR]: (state, action: PayloadAction<string>) => {
        state.countriesLoading = false;
        state.countriesError = action.payload;
    },
});

export default settingsReducer;