export class AuthUser implements IAuthUser {
    firstName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    personId?: string;
    authUserId?: string | undefined;
    photoUrl?: string | undefined;
    accountId?: string | undefined;
    isAdmin?: boolean;
    permissions?: string[] | undefined;

    get displayName(): string {
        return this.firstName + ' ' + this.lastName;
    }

    constructor(data?: IAuthUser) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.firstName = _data['firstName'];
            this.lastName = _data['lastName'];
            this.email = _data['email'];
            this.personId = _data['personId'];
            this.authUserId = _data['authUserId'];
            this.photoUrl = _data['photoUrl'];
            this.accountId = _data['accountId'];
            this.isAdmin = _data['isAdmin'];
            if (Array.isArray(_data['permissions'])) {
                this.permissions = [] as any;
                for (const item of _data['permissions'])
                    this.permissions!.push(item);
            }
        }
    }

    static fromJS(data: any): AuthUser {
        data = typeof data === 'object' ? data : {};
        const result = new AuthUser();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['firstName'] = this.firstName;
        data['lastName'] = this.lastName;
        data['email'] = this.email;
        data['personId'] = this.personId;
        data['authUserId'] = this.authUserId;
        data['photoUrl'] = this.photoUrl;
        data['accountId'] = this.accountId;
        data['isAdmin'] = this.isAdmin;
        if (Array.isArray(this.permissions)) {
            data['permissions'] = [];
            for (const item of this.permissions)
                data['permissions'].push(item);
        }
        return data;
    }
}

export interface IAuthUser {
    firstName?: string | undefined;
    lastName?: string | undefined;
    readonly displayName?: string | undefined;
    email?: string | undefined;
    personId?: string;
    authUserId?: string | undefined;
    photoUrl?: string | undefined;
    accountId?: string | undefined;
    isAdmin?: boolean;
    permissions?: string[] | undefined;
}
