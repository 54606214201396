export default {
  siteUrl: process.env.REACT_APP_SITE_URL,
  publicFilesUrl: process.env.REACT_APP_PUBLIC_FILES
};

export const coreApiConfig = {
  Url: process.env.REACT_APP_CORE_URL,
  Paths: {
    auth: '/auth',
    account: '/account',
    accountParticipant: '/account-participant',
    adminUser: '/admin-user',
    device: '/device',
    instructorUser: '/instructor-user',
    participant: '/participant',
    session: '/session',
    system: '/system',
    task: 'task',
    libraryCategory: '/category',
    backgroundWork: '/background-work'
  }
};
export const imageConfig = {
  imageSize: {
    imagesLarge: '/large'
  },
};
export const filesApiConfig = {
  Url: process.env.REACT_APP_FILES_URL,
  Paths: {
    file: '/files',
    image: '/image',
    system: '/system',
  }
};

export const uppyCompanionConfig = {
  Url: process.env.REACT_APP_UPPY_URL,
};

export const messagingApiConfig = {
  Url: process.env.REACT_APP_ACTIVITY_URL,
  Paths: {
    events: '/events',
    inbox: '/inbox',
    thread: '/thread',
  }
};

export const authConfig = {
  clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
  authority:  process.env.REACT_APP_AZURE_AUTHORITY,
  redirectUri:  process.env.REACT_APP_AZURE_REDIRECT,
  postLogoutRedirectUri:  process.env.REACT_APP_AZURE_LOGOUT,
  mapsSubscriptionKey: process.env.REACT_APP_AZURE_MAP_KEY
}

export const siteConfig = {
  siteName: 'Operator - Instructor',
  footerText: 'Operator © 2023',
  dateFormatLong: 'do MMM, yyyy',
  dateFormatShort: 'MMM do yyyy',
  dateTimeFormatLong: 'do MMM, yyyy, h:mm a',
  dateTimeFormatFile: 'yyyyMMddHHmm',
  tokenExpiryMinutes: 45,
  debugFooter: process.env.REACT_APP_DEBUG_FOOTER
};

export const uppyConfig = {
  target: '#uppyHolder',
  endpoint: `${filesApiConfig.Url}${filesApiConfig.Paths.file}/temp`,
  DashboardInline: true,
  Webcam: false,
  GoogleDrive: false,
  OneDrive: true,
  Dropbox: false,
  Instagram: false,
  autoProceed: true,
  restrictions: {
    maxFileSize: 20000000,
    maxNumberOfFiles: 5,
    minNumberOfFiles: 1,
    allowedFileTypes: ['image/*', 'video/*', 'text/*', '.doc', '.dot', '.pdf', '.rtf', '.docx', '.xls', '.xlt', '.xlm', '.xlsx', '.ppt', '.pptx', '.pptm', '.pub', '.zip', '.rar', '.PDF', '.PPT', '.PPTX', '.RAR', '.XLS', '.XLSX', '.DOC', '.DOCX', '.XLT', '.RTF']
  },
};

export const uppyImagesConfig = {
  target: '#uppyHolder',
  endpoint: `${filesApiConfig.Url}${filesApiConfig.Paths.image}/temp`,
  DashboardInline: true,
  Webcam: false,
  GoogleDrive: false,
  Dropbox: false,
  Instagram: false,
  autoProceed: true,
  restrictions: {
    maxFileSize: 20000000,
    maxNumberOfFiles: 5,
    minNumberOfFiles: 1,
    allowedFileTypes: ['image/*']
  },
};
