import {TempFileMetadata} from './TempFileMetadata';
import {SystemFileMetadata} from './SystemFileMetadata';
import {MessageFileMetadata} from './MessageFileMetadata';
import {PersonFileMetadata} from './PersonFileMetadata';
import {CompanyFileMetadata} from './CompanyFileMetadata';
import {UserFileMetadata} from './UserFileMetadata';
import {MetadataType} from '../Enums';

export type FileMetadata = TempFileMetadata | SystemFileMetadata | MessageFileMetadata | PersonFileMetadata | CompanyFileMetadata | UserFileMetadata;

export const mapToFileMetadata = (data: any | undefined) => {
  switch (data['metadataType']) {
    case MetadataType.SystemFile:
      return SystemFileMetadata.fromJS(data);
    case MetadataType.MessageFile:
      return MessageFileMetadata.fromJS(data);
    case MetadataType.PersonFile:
      return PersonFileMetadata.fromJS(data);
    case MetadataType.CompanyFile:
      return CompanyFileMetadata.fromJS(data);
    case MetadataType.UserFile:
      return UserFileMetadata.fromJS(data);
    default:
      return TempFileMetadata.fromJS(data);
  }
}