import React, {useContext, useMemo} from 'react';
import {Icon, ListItem, ListItemText} from '@mui/material';
import clsx from 'clsx';
import {Badge, NavLink} from '../../../index';
import Box from '@mui/material/Box';
import useStyles from './VerticalItem.style';
import AppContext from '../../../utility/AppContext';
import {checkPermission} from '../../../utility/Utils';
import {useSelector} from 'react-redux';
import {NavItemProps} from '../../../../modules/routesConfig';
import {selectCurrentUser} from '../../../../redux/auth/Selectors';

interface VerticalItemProps {
  item: NavItemProps;
  level: number;
}

const VerticalItem: React.FC<VerticalItemProps> = ({item, level}) => {
  const {themeMode} = useContext(AppContext);
  const classes = useStyles({level, themeMode});
  const user = useSelector(selectCurrentUser);
  const hasPermission = useMemo(() => checkPermission(item.auth, user?.permissions), [
    item.auth,
    user,
  ]);
  if (!hasPermission) {
    return null;
  }
  return (
      <ListItem
          button
          component={NavLink}
          to={item.url}
          activeClassName='active'
          className={clsx(classes.navItem, 'nav-item')}
          exact={item.exact}>
        {item.icon && (
            <Box component='span' mr={6}>
              <Icon
                  className={clsx(classes.listIcon, 'nav-item-icon')}
                  color='action'>
                {item.icon}
              </Icon>
            </Box>
        )}
        <ListItemText
            primary={item.label}
            classes={{primary: clsx('nav-item-text', classes.listItemText), secondary: clsx('nav-item-text', classes.listItemText)}}
        />
        {item.count && (
            <Box mr={1}>
              <Badge count={item.count} color={item.color} />
            </Box>
        )}
      </ListItem>
  );
};

export default React.memo(VerticalItem);
