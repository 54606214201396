import {ICommonSavePayload} from '../Common.actions';
import {InstructorUserEdit} from '../../types/requests/InstructorUserEdit';
import {AxiosResponse} from 'axios';
import authenticatedApi from '../../@template/services/auth/authApi/authenticatedApi';
import {coreApiConfig} from '../../config';
import {InstructorUserView} from '../../types/views/InstructorUserView';
import {all, call, put, takeEvery} from '@redux-saga/core/effects';
import {
    INSTRUCTOR_USER_SEARCH,
    instructorUserLoadError,
    instructorUserLoadSuccess,
    INSTRUCTOR_USERS,
    instructorUserSaveError,
    instructorUserSaveSuccess,
    instructorUserSearchError,
    instructorUserSearchSuccess,
    INSTRUCTOR_USER_SESSION_SEARCH,
    instructorUserSessionsSearchSuccess,
    instructorUserSessionsError
} from './Actions';
import {parseErrorInfo, parseErrorToErrorClass} from '../../@template/helpers/errors';
import {SearchRequest} from '../../types/common/SearchRequest';
import {InstructorUserSearchRequest} from '../../types/requests/InstructorUserSearchRequest';
import {PagedEntityListOfInstructorUserSearchItem} from '../../types/views/PagedEntityListOfInstructorUserSearchItem';
import {PayloadAction} from '@reduxjs/toolkit';
import {SessionSearchRequest} from '../../types/requests/SessionSearchRequest';
import {PagedEntityListOfSessionSearchItem} from '../../types/views/PagedEntityListOfSessionSearchItem';


export function* SaveInstructorUser({ id, submission }: ICommonSavePayload<InstructorUserEdit>) {
    console.log('SAGA - instructorUserSaga - SaveInstructorUser');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: id ? 'PUT' : 'POST',
            url: id ? `${coreApiConfig.Paths.instructorUser}/${id}` : coreApiConfig.Paths.instructorUser,
            data: submission
        });
        const instructorUser: InstructorUserView = InstructorUserView.fromJS(res.data);
        yield put(instructorUserSaveSuccess(instructorUser));
    } catch (err) {
        yield put(instructorUserSaveError(parseErrorToErrorClass(err)));
    }
}


export function* LoadInstructorUser(id: string) {
    console.log('SAGA - instructorUserSaga - LoadInstructorUser');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: 'GET',
            url: `${coreApiConfig.Paths.instructorUser}/${id}`,
        });
        const instructorUser: InstructorUserView = InstructorUserView.fromJS(res.data);
        yield put(instructorUserLoadSuccess(instructorUser));
    } catch (err) {
        yield put(instructorUserLoadError(parseErrorInfo(err)));
    }
}

export function* SearchInstructorUsers({ page, pageSize, searchParams, sortBy, sortDirection }: SearchRequest<InstructorUserSearchRequest>) {
    console.log('SAGA - instructorUserSaga - SearchInstructorUsers');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: 'PUT',
            url: `${coreApiConfig.Paths.instructorUser}/search?page=${page}&page_size=${pageSize}&sort=${sortBy}&order=${sortDirection}`,
            data: searchParams
        });
        const results: PagedEntityListOfInstructorUserSearchItem = PagedEntityListOfInstructorUserSearchItem.fromJS(res.data);
        yield put(instructorUserSearchSuccess(results));
    } catch (err) {
        yield put(instructorUserSearchError(parseErrorInfo(err)));
    }
}

export function* SearchInstructorSessions({ page, pageSize, searchParams, sortBy, sortDirection }: SearchRequest<SessionSearchRequest>) {
    console.log('SAGA - instructorUserSaga - SearchInstructorSessions');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: 'PUT',
            url: `${coreApiConfig.Paths.session}/search?page=${page}&page_size=${pageSize}&sort=${sortBy}&order=${sortDirection}`,
            data: searchParams
        });
        const results: PagedEntityListOfSessionSearchItem = PagedEntityListOfSessionSearchItem.fromJS(res.data);
        yield put(instructorUserSessionsSearchSuccess(results));
    } catch (err) {
        yield put(instructorUserSessionsError(parseErrorInfo(err)));
    }
}

export function* watchSaveInstructorUser(){
    yield takeEvery(INSTRUCTOR_USERS.SAVE, ({ payload } : PayloadAction<ICommonSavePayload<InstructorUserEdit>>) => SaveInstructorUser(payload));
}

export function* watchLoadInstructorUser(){
    yield takeEvery(INSTRUCTOR_USERS.LOAD, ({ payload } : PayloadAction<string>) => LoadInstructorUser(payload));
}

export function* watchRefreshInstructorUser(){
    yield takeEvery(INSTRUCTOR_USERS.REFRESH, ({ payload } : PayloadAction<string>) => LoadInstructorUser(payload));
}

export function* watchSearchInstructorUsers(){
    yield takeEvery(INSTRUCTOR_USER_SEARCH.LOAD, ({ payload } : PayloadAction<SearchRequest<InstructorUserSearchRequest>>) => SearchInstructorUsers(payload));
}

export function* watchSearchInstructorSessions(){
    yield takeEvery(INSTRUCTOR_USER_SESSION_SEARCH.LOAD, ({ payload } : PayloadAction<SearchRequest<SessionSearchRequest>>) => SearchInstructorSessions(payload));
}

export function* watchSearchInstructorSessionsRefresh(){
    yield takeEvery(INSTRUCTOR_USER_SESSION_SEARCH.REFRESH, ({ payload } : PayloadAction<SearchRequest<SessionSearchRequest>>) => SearchInstructorSessions(payload));
}


export default function* instructorUserSaga() {
    yield all ([
        call(watchSaveInstructorUser),
        call(watchRefreshInstructorUser),
        call(watchLoadInstructorUser),
        call(watchSearchInstructorUsers),
        call(watchSearchInstructorSessions),
        call(watchSearchInstructorSessionsRefresh)
    ])
}