import { Account } from 'types/models/Account';
import { InstructorAccountAccess } from './InstructorAccountAccess';
import { AgeRange } from './AgeRange';

export class InstructorUserSettings implements IInstructorUserSettings {
    ageRanges?: AgeRange[] | undefined;
    account?: Account | undefined;
    instructorAccounts?: InstructorAccountAccess[] | undefined;

    constructor(data?: IInstructorUserSettings) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data['ageRanges'])) {
                this.ageRanges = [] as any;
                for (const item of _data['ageRanges'])
                    this.ageRanges!.push(AgeRange.fromJS(item));
            }
            this.account = _data['account'] ? Account.fromJS(_data['account']) : <any>undefined;
            if (Array.isArray(_data['instructorAccounts'])) {
                this.instructorAccounts = [] as any;
                for (const item of _data['instructorAccounts'])
                    this.instructorAccounts!.push(InstructorAccountAccess.fromJS(item));
            }
        }
    }

    static fromJS(data: any): InstructorUserSettings {
        data = typeof data === 'object' ? data : {};
        const result = new InstructorUserSettings();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.ageRanges)) {
            data['ageRanges'] = [];
            for (const item of this.ageRanges)
                data['ageRanges'].push(item.toJSON());
        }
        data['account'] = this.account ? this.account.toJSON() : <any>undefined;
        if (Array.isArray(this.instructorAccounts)) {
            data['instructorAccounts'] = [];
            for (const item of this.instructorAccounts)
                data['instructorAccounts'].push(item.toJSON());
        }
        return data;
    }
}

export interface IInstructorUserSettings {
    ageRanges?: AgeRange[] | undefined;
    account?: Account | undefined;
    instructorAccounts?: InstructorAccountAccess[] | undefined;
}